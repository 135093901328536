@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');

* {
  font-family: "Inter";
  box-sizing: border-box;
}

body {
  background-color: #EFF4FF;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  min-height: 100dvh;
  flex: 1;
}

button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  color: #000;
  text-decoration: none;
}

input,
select,
textarea {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
}

textarea {
  resize: none;
}

select {
  -webkit-appearance: none;
}

#root {
  width: 100%;
  flex: 1;
  display: flex;
}

.App {
  width: 100%;
  flex: 1;
}

.App >main {
  padding: 90px 16px 60px;
  gap: 32px;
  width: 100%;
}

.toolbar-hidden {
  display: none !important;
}

.toolbar-shown {
  display: flex !important;
}

.col-2 {
  display: flex;
  gap: 8px;
  align-items: center;
}


.tox-tinymce {
  width: 70% !important;
}

@media (max-width: 990px) {
  .tox-tinymce {
    width: 81% !important;
  }
}
